import * as React from 'react';
import cn from 'classnames';
import MsDyn365, { IImageData } from '@msdyn365-commerce/core';
import { IMenuItemData } from '../navigation-tiles.props.autogenerated';

interface INavTile{
	navData: IMenuItemData;
}

interface INavTileState{
	active: boolean;
}

export class NavTile extends React.Component<INavTile, INavTileState>{
	private readonly node: React.RefObject<HTMLDivElement>;

	constructor(props: INavTile){
		super(props);
		this.node = React.createRef();
		this.state = { active:false };
	}

	public componentDidMount(): void{
		if (MsDyn365.isBrowser){
			document.body.addEventListener('mousedown', this._handleClickOutside);
			/*document.body.addEventListener('focusout', this._handleFocusOutside);*/
		}
	}

	public componentWillUnmount(): void{
		if (MsDyn365.isBrowser){
			document.body.removeEventListener('mousedown', this._handleClickOutside, false);
			/*document.body.removeEventListener('focusout', this._handleFocusOutside, false);*/
		}
	}

	/* Sub Component Builders */
	private _buildButtonImage(imageData: IImageData){
		return <img className="img" src={imageData.src} alt={imageData.altText} />;
	}

	public render(){
		const { navData } = this.props;
		const elementID = `${navData.linkText }-dropdown-menu`;
		//Do not render without the main title
		if(!navData.linkText || !navData.image){return null}

		return (<div className="navigation-tiles__group" ref={this.node}>
			<button
				type="button"
				aria-label={`Opens the ${ navData.linkText.toLowerCase() } menu`}
				aria-expanded={this.state.active}
				aria-controls={elementID}
				className={`navigation-tiles__link ${cn({ 'active': this.state.active })}`}
				onClick={this._handleClick}
			>
				{this._buildButtonImage(navData.image)}
				<span className="title">{navData.linkText}</span>
			</button>
			<div id={elementID} className={`navigation-tiles__subs ${cn({'active': this.state.active})}`}>
				{navData.subTiles?.map((st,idx) => {
					return (
						<a key={idx} className="navigation-tiles__sublink"
							href={st.linkUrl.destinationUrl}
							aria-label={st.ariaLabel}
						>
							<div className="sub_img_wrapper">
								<img className="img" src={st.image.src} alt={st.image.altText} />
							</div>
							<span className="title">{st.linkText}</span>
						</a>
					)
				})}
			</div>
		</div>);
	}

	private readonly _handleClick = () => this.setState({active: !this.state.active});
	private readonly _handleClickOutside = (e:MouseEvent) => {
		if(this.node.current && !this.node.current.contains(e.target as Node)){
			this.setState({active: false})
		}
		e.stopPropagation();
	}
}

export default NavTile;