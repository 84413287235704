import * as React from 'react';
import { IMenuItemData, INavigationTilesProps } from './navigation-tiles.props.autogenerated';
import { ContentEditableEvent, IImageSettings, Text } from '@msdyn365-commerce/core';

export interface INavigationTilesViewProps extends INavigationTilesProps<{}>{
	heading?:object,
	navTiles?: IMenuItemData[],
	className?: string,
	imageSettings:IImageSettings
}

/**
 * NavigationTiles component
 * @extends {React.PureComponent<INavigationTilesProps<{}>>}
 */
class NavigationTiles extends React.PureComponent<INavigationTilesProps<{}>>{

	public render(): JSX.Element | null {
		const {
			heading,
			navTiles,
			className
		} = this.props.config;

		const buildHeading = ():JSX.Element|null => {
			if(heading){
				return (<Text
					className={"navigation-tiles__heading"}
					tag={heading.tag || "h2"}
					text={heading.text}
					editProps={{ onEdit: this.handleTextChange, requestContext: this.props.context.request }}
				/>)
			}
			return null;
		}

		return this.props.renderView({
			...this.props,
			heading: buildHeading(),
			navTiles: navTiles,
			className: className
		}) as React.ReactElement;
	}

	public handleTextChange = (event: ContentEditableEvent): void => {this.props.config.heading.text = event.target.value};
}

export default NavigationTiles;
