import * as React from 'react';
import { INavigationTilesViewProps } from './navigation-tiles';
import NavTile from './components/NavTile';

export class NavigationTilesView extends React.PureComponent<INavigationTilesViewProps>{
	constructor(props: INavigationTilesViewProps){
		super(props);
	}

	public render(){
		const {
			heading,
			navTiles,
			className
		} = this.props;
		//If we don't have nav tiles, don't render the element.
		if(!navTiles) {return null}

		return (
			<div className={`navigation-tiles ${className}`}>
				{heading}
				<div className="navigation-tiles__container">
					{navTiles.map((nt,idx) => {
						return <NavTile key={idx} navData={nt}/>
					})}
				</div>
			</div>
		);
	}
}
export default NavigationTilesView;