/*!
 * Example of how this should work!
 * https://www.bgefinancial.com/calculator
 */

import * as React from 'react';
import {IFinanceCalculatorViewProps} from './finance-calculator';
import {useEffect, useState} from "react";

const FinancialCalculatorBlockView: React.FC<IFinanceCalculatorViewProps> = props => {
	const {title, text, moduleClass} = props;
	const [query, setQuery] = useState("");
	const [cost, setCost] = useState([""]);
	const [buying, setBuying] = useState("");
	const [power, setPower] = useState("");
	const prime_rate:number = 8.5;
	const rates: {year: number;rate: number}[] = [
		{year:2, rate:2.5},
		{year:3, rate:3.0},
		{year:4, rate:4.0},
		{year:5, rate:5.0}
	];
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency', currency: 'USD', minimumFractionDigits: 2
	})

	useEffect(() => {
		const timeOut = setTimeout(()=> {
			const principal:number = Number(query);
			//Don't run calculations on values less than $999
			if(principal <= 999) {
				setCost(["", "", "", ""]);
				return;
			}
			//We have a valid number, run calculations  ((10,000 * .085 * 5)+10,000)/5 = 2850 yearly payments
			const payments:string[] = [];
			for(const data of rates){
				const rate = (prime_rate + data.rate)/100;
				payments.push(`${formatter.format( ((principal * rate * data.year)+principal)/data.year )} yearly`);
			}
			setCost(payments);
		}, 500);
		return () => clearTimeout(timeOut);
	}, [query]);
	useEffect(() => {
		const timeOut = setTimeout(() => {
			//We have a valid number, run calculation  ((tY)/(rt+1) = Initial Principal (inverse of the above equation)
			const rate:number = (prime_rate + rates[3].rate)/100;
			setPower((!(buying && buying.length > 3) ? "" : `${formatter.format( (5 * Number(buying)) / (rate * 5 +1) )}`));
		}, 500);
		return () => clearTimeout(timeOut);
	}, [buying]);

	return (
		<div className={`financial-calculator ${moduleClass}`}>
			{title}
			{text}
			<article className="fanacialCalc payments">
				<fieldset>
					<legend id="purchase-price">Calculate Payments by Purchase Price</legend>
					<input
						type="number"
						className="form-control purchase-price" aria-labelledby="purchase-price"
						value={query}
						onChange={event => setQuery(event.target.value)}
					/>
					<ul className="purchase-price-calc">
						<li data-term="24"><strong>2 years:</strong> {cost[0]}</li>
						<li data-term="36"><strong>3 years:</strong> {cost[1]}</li>
						<li data-term="48"><strong>4 years:</strong> {cost[2]}</li>
						<li data-term="60"><strong>5 years:</strong> {cost[3]}</li>
					</ul>
				</fieldset>
			</article>
			<article className="fanacialCalc buying">
				<fieldset>
					<legend>Purchasing Power by Yearly Budget</legend>
					<div className="input-group mb-3">
						<span className="input-group-text">$</span>
						<input
							name="buying-power"
							type="number"
							className="form-control"
							placeholder="Your Annual Budget"
							value={buying}
							onChange={event => setBuying(event.target.value)}
						/>
					</div>
					<p><strong>Overall Purchasing Power:</strong> {power}</p>
				</fieldset>
			</article>
		</div>
	);
};

export default FinancialCalculatorBlockView;